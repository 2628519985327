<script>
import PageHeader from "@/components/page-header.vue";
import InquiresList from "@/views/pages/inquires/components/List.vue";
import ManagerInquiryByStatus from "@/views/pages/inquires/components/ManagerInquiryByStatus.vue";
import ManagerAverageResponseTime from "@/views/pages/inquires/components/ManagerAverageTime.vue";
import userStore from '@/state/store'
import axios from "axios";
import Months from "@/core/Months";
import moment from "moment";
import {INQUIRY_KPI} from "@/core/KPI";
import ManagerKPIScore from "@/views/pages/inquires/components/ManagerKPIScore.vue";

function getChartColorsArray(colors) {
  colors = JSON.parse(colors);
  return colors.map(function (value) {
    var newValue = value.replace(" ", "");
    if (newValue.indexOf(",") === -1) {
      var color = getComputedStyle(document.documentElement).getPropertyValue(newValue);
      if (color) {
        color = color.replace(" ", "");
        return color;
      } else return newValue;
    } else {
      var val = value.split(',');
      if (val.length == 2) {
        var rgbaColor = getComputedStyle(document.documentElement).getPropertyValue(val[0]);
        rgbaColor = "rgba(" + rgbaColor + "," + val[1] + ")";
        return rgbaColor;
      } else {
        return newValue;
      }
    }
  });
}

export default {
  name: "Index",
  components: {
    PageHeader,
    InquiresList,
    ManagerInquiryByStatus,
    ManagerAverageResponseTime,
    ManagerKPIScore
  },
  data() {
    return {
      title: "Inquires",
      breadcrumbs: [
        {
          text: "Home",
          href: "/"
        },
        {
          text: "Inquires",
          active: true
        }
      ],

      statistics: [],
      is_loading: true,
      is_loading_kpi_data: true,

      right_sidebar_is_hidden: true,

      dates: {
        start: null,
        end: null,
        options: Months,
        option_value: '',
      },

      all_time_inquires_statistics: [],
      INQUIRY_KPI: INQUIRY_KPI,

      columnChart: {
        series: [{
          name: "Inflation",
          data: [],
        },],
        chartOptions: {
          chart: {
            height: 350,
            type: "bar",
            toolbar: {
              show: false,
            },
          },
          plotOptions: {
            bar: {
              dataLabels: {
                position: "top", // top, center, bottom
              },
            },
          },
          dataLabels: {
            enabled: true,
            formatter: function (val) {
              return val + "%";
            },
            offsetY: -20,
            style: {
              fontSize: "14px",
              colors: ["#000000"],
            },
          },
          colors: getChartColorsArray('["--vz-success"]'),
          grid: {
            borderColor: "#f1f1f1",
          },
          xaxis: {
            categories: [
              "Response Time",
              "Follow-up",
              "Conversion Rate",
              "Leads"
            ],
            position: "bottom",
            labels: {
              style: {
                colors: ["#000", "#000", "#000", "#000"], // Set black color for all labels
                fontSize: "12px", // Optional: Adjust font size if needed
              },
            },
            axisBorder: {
              show: false,
            },
            axisTicks: {
              show: false,
            },
            tooltip: {
              enabled: true,
              offsetY: -35,
            },
          },
          fill: {
            gradient: {
              shade: "light",
              type: "horizontal",
              shadeIntensity: 0.25,
              gradientToColors: undefined,
              inverseColors: true,
              opacityFrom: 1,
              opacityTo: 1,
              stops: [50, 0, 100, 100],
            },
          },
          yaxis: {
            axisBorder: {
              show: false,
            },
            axisTicks: {
              show: false,
            },
            labels: {
              show: false,
              formatter: function (val) {
                return val + "%";
              },
            },
          },
          title: {
            text: "KPI Metrics",
            floating: true,
            offsetY: 320,
            align: "center",
            style: {
              color: "#444",
              fontWeight: 500,
            },
          },
        }
      }
    };
  },
  methods: {
    async getStatistics() {
      try {
        this.is_loading = true
        let response = await axios.get('/inquiry/statistic/kpi/', {
          params: {
            date_from: this.dates.start,
            date_to: this.dates.end,
          }
        })
        this.statistics = response.data || []
        setTimeout(() => {
          this.is_loading = false
        }, 500)
      } catch {
        setTimeout(() => {
          this.is_loading = false
        }, 500)
        alert("eror")
      }
    },
    async onTableUpdated() {
      await this.getStatistics()
      await this.$refs.managerInquiryByStatus.getStatistics()
    },
    async setDate(option) {
      if (this.dates.option_value === option || option === this.last_month_option) return
      this.dates.option_value = option
    },
    sidebarVisibility(hide) {
      localStorage.setItem('right_sidebar_is_hidden', hide)
      this.right_sidebar_is_hidden = hide
    },
  },
  computed: {
    user() {
      return userStore.state.user
    },
    statuses() {
      if (this.is_loading) return []
      try {
        let manager = this.statistics.find(i => i.manager.id === this.user.id)
        if (manager) {
          return (manager.statuses || []).map(i => {
            let item = {...i}

            if (item.status.toLowerCase() === 'pending') {
              item.kpi_inquiries = []
            } else if (item.status.toLowerCase() === 'quoted') {
              item.kpi_inquiries = this.statistics[0].response_time || []
            } else if (item.status.toLowerCase() === 'success') {
              item.kpi_inquiries = this.statistics[0].conversion || []
            } else if (item.status.toLowerCase() === 'fail') {
              item.kpi_inquiries = this.statistics[0].completion || []
            }

            return item
          })
        } else {
          return []
        }
      } catch {
        return []
      }
    },
    pending_inquires_count() {
      try {
        let pending = this.statuses.find(i => i.status === 'Pending')
        if (pending) {
          return pending.count
        } else {
          return 0
        }
      } catch {
        return 0
      }
    },
    response_time_details() {
      if (this.is_loading) {
        return []
      } else {
        try {
          let manager = this.statistics.find(i => i.manager.id === this.user.id)
          if (manager) {
            return Object.keys(manager.response_time.details).flatMap(grade =>
                manager.response_time.details[grade].inquiries
            );
          } else {
            return []
          }
        } catch {
          return []
        }
      }
    },
    completion_details() {
      if (this.is_loading) {
        return []
      } else {
        try {
          let manager = this.statistics.find(i => i.manager.id === this.user.id)
          if (manager) {
            return Object.keys(manager.completion.details).flatMap(grade =>
                manager.completion.details[grade].inquiries
            );
          } else {
            return []
          }
        } catch {
          return []
        }
      }
    },
    average_response_time() {
      let response_times = this.response_time_details.map(i => i.quote_time);

      const totalSeconds = response_times.reduce((sum, duration) => sum + parseFloat(duration), 0);

      const averageSeconds = totalSeconds / (response_times.length || 1);

      return {
        days: Math.floor(averageSeconds / 86400),
        hours: Math.floor((averageSeconds % 86400) / 3600),
        minutes: Math.floor((averageSeconds % 3600) / 60),
        seconds: Math.floor(averageSeconds % 60),
      };
    },
    average_completion_time() {
      let response_times = this.completion_details.map(i => i.resolution_time);

      const totalSeconds = response_times.reduce((sum, duration) => sum + parseFloat(duration), 0);

      const averageSeconds = totalSeconds / (response_times.length || 1);

      return {
        days: Math.floor(averageSeconds / 86400),
        hours: Math.floor((averageSeconds % 86400) / 3600),
        minutes: Math.floor((averageSeconds % 3600) / 60),
        seconds: Math.floor(averageSeconds % 60),
      };
    },
    overall_kpi_percentage() {
      if (this.is_loading) return 0
      try {
        let manager = this.statistics.find(i => i.manager.id === this.user.id)
        if (manager) {
          let response_time = manager.response_time.target
          let completion = manager.completion.target
          let conversion = manager.conversion.target
          let leads = manager.leads.target
          return (
              response_time * (this.INQUIRY_KPI.response_time.weight / 100) +
              completion * (this.INQUIRY_KPI.completion.weight / 100) +
              conversion * (this.INQUIRY_KPI.conversion.weight / 100) +
              leads * (this.INQUIRY_KPI.leads.weight / 100)
          ).toFixed(1)
        } else {
          return 0
        }
      } catch (e) {
        console.log(e)
        return 0
      }
    },
    kpi_data() {
      if (this.is_loading) {
        return {}
      } else {
        try {
          let manager = this.statistics.find(i => i.manager.id === this.user.id)
          return manager ? manager : null
        } catch {
          return {}
        }
      }
    },
    last_month_option() {
      let month = moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD');

      let monthIndex = moment(month).month();
      return `month_${monthIndex + 1}`
    },
    created_month_name() {
      try {
        const date = moment(this.$route.query.created, 'YYYY-MM-DD');
        const startOfMonth = date.startOf('month').format('MMMM');

        return startOfMonth
      } catch {
        return '***'
      }
    },
    columnChartSeries() {
      return [{
        name: "KPI Score",
        data: [
          this.kpi_data.response_time.actual,
          this.kpi_data.completion.actual,
          this.kpi_data.conversion.target,
          this.kpi_data.leads.target,
        ],
      }]
    }
  },
  async mounted() {

    if (String(localStorage.getItem('right_sidebar_is_hidden'))) {
      this.right_sidebar_is_hidden = JSON.parse(localStorage.getItem('right_sidebar_is_hidden'))
    } else {
      this.right_sidebar_is_hidden = false
    }

    if (this.$route.query.created) {
      try {
        const date = moment(this.$route.query.created, 'YYYY-MM-DD');
        const startOfMonth = date.startOf('month').format('YYYY-MM-DD'); // Start date of the month
        const endOfMonth = date.endOf('month').format('YYYY-MM-DD'); // End date of the month

        this.dates.start = startOfMonth;
        this.dates.end = endOfMonth;
      } catch {
        console.log(this.$route.query.created)
      }
    }
    await this.getStatistics()
  },
  watch: {
    "$route.query.created": {
      handler(newValue) {
        if (newValue) {
          const date = moment(newValue, 'YYYY-MM-DD');
          const startOfMonth = date.startOf('month').format('YYYY-MM-DD'); // Start date of the month
          const endOfMonth = date.endOf('month').format('YYYY-MM-DD'); // End date of the month

          this.dates.start = startOfMonth;
          this.dates.end = endOfMonth;

          this.getStatistics()
          this.$refs.managerInquiryByStatus.getStatistics()
        }
      },
    }
  }
}
</script>

<template>
  <PageHeader :title="title" :items="breadcrumbs"/>
  <ManagerKPIScore ref="managerKPIScore" :user_kpi_data="kpi_data" :loading="is_loading"
                   :pending_inquires_count="pending_inquires_count" :overall_kpi_percentage="overall_kpi_percentage"
                   :start_date="dates.start" :end_date="dates.end"/>

  <div v-if="right_sidebar_is_hidden" class="position-absolute" style="z-index: 100; right: 0; top: 300px">
    <b-button @click="sidebarVisibility(false)" variant="success" size="sm">
      <i class="mdi mdi-chevron-left fs-22"></i>
    </b-button>
  </div>

  <div class="row gy-4">
    <div class="col-12">
      <BAlert show variant="info" class="mb-0">
        <div class="d-flex align-items-center gap-3 h4 mb-0">
          <strong class="h5 mb-0">Reminder:</strong>
          <span class="h5 mb-0">New KPI targets (from March 2025):</span>
          <span class="badge badge-gradient-info">35 inquiries → 90%</span>
          <span class="badge badge-gradient-info">36-50 inquiries → 80%</span>
          <span class="badge badge-gradient-info">51+ inquiries → 75%</span>
        </div>
      </BAlert>
    </div>
    <div :class="{
      'col-xxl-12': right_sidebar_is_hidden,
      'col-xxl-9': !right_sidebar_is_hidden,
    }">
      <div class="mb-3">
        <InquiresList @update-statistics="onTableUpdated()"/>
      </div>
    </div>
    <div class="col-xxl-3" v-if="!right_sidebar_is_hidden">
      <div>
        <b-card>
          <div class="w-100">

            <div class="d-flex align-items-center">
              <div class="avatar-sm gap-3">
                <div class="avatar-title text-uppercase rounded-circle flex-shrink-0 rounded fs-22 bg-success">
                  {{ (user ? user.full_name : 'Unknown manager').charAt(0) }}
                </div>
              </div>

              <div class="ms-3 flex-grow-1">
                <div class="d-flex justify-content-between align-items-center">
                  <h5 class="fs-16 mb-1 fw-bold">
                    {{ user ? user.full_name : 'Unknown manager' }}
                  </h5>
                </div>
                <p class="text-muted mb-0">
                  {{ user ? user.role : 'Anonymous' }}
                </p>
              </div>
              <b-button @click="sidebarVisibility(true)" variant="light" class="mb-0">
                Hide
                <i class="mdi mdi-eye-off ms-1 align-middle"></i>
              </b-button>
            </div>

            <h3 class="ff-secondary fw-bold mt-4 cfs-22">
              {{ overall_kpi_percentage }}
              <i class="mdi mdi-percent"></i>
            </h3>
            <p class="text-muted fs-14 mb-0">
              This is your KPI score for {{ created_month_name }}.
              This score is derived by averaging key metrics such as 'Response Time KPI,' 'Follow-up KPI,'
              'Conversion Rate KPI,' and 'New Leads Generated.
            </p>
            <template v-if="kpi_data && !is_loading">
              <apexchart class="apex-charts" height="200" dir="ltr" :series="columnChartSeries"
                         :options="columnChart.chartOptions"></apexchart>

              <b-button @click="this.$refs.managerKPIScore.openModal()" variant="soft-success" class="w-100 mt-3">
                Learn More
                <i class="mdi mdi-arrow-right ms-1"></i>
              </b-button>
            </template>
          </div>
        </b-card>

        <ManagerInquiryByStatus ref="managerInquiryByStatus" :date_start="dates.start" :date_to="dates.end"/>
        <ManagerAverageResponseTime ref="managerAverageResponseTime" :loading="is_loading" label="Average Response Time"
                                    description="Represents the average time taken to provide quotes for pending inquiries, showcasing how quickly these inquiries are being quoted"
                                    :days="average_response_time.days" :hours="average_response_time.hours"
                                    :minutes="average_response_time.minutes" :seconds="average_response_time.seconds"/>
        <ManagerAverageResponseTime ref="managerAverageResponseTime" :loading="is_loading"
                                    label="Average Completion Time"
                                    description="Represents the average time taken to update the status of inquiries from 'quoted' to either 'success' or 'fail.' It measures how quickly inquiries progress through the quoting process to their final outcomes."
                                    :days="average_completion_time.days" :hours="average_completion_time.hours"
                                    :minutes="average_completion_time.minutes"
                                    :seconds="average_completion_time.seconds"/>
      </div>
    </div>
  </div>
</template>

<style scoped></style>
