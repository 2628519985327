<script>
import Table from "@/components/tables/table.vue";
import ViewText from "@/views/pages/inquires/modals/ViewText.vue";
import ViewComment from "@/views/pages/inquires/modals/ViewComment.vue";
import CreateInquiry from "@/views/pages/inquires/modals/CreateInquiry.vue";
import EditInquiry from "@/views/pages/inquires/modals/EditInquiry.vue";
import DeleteInquiry from "@/views/pages/inquires/modals/DeleteInquiry.vue";
import InquiryToSuccess from "@/views/pages/inquires/modals/InquiryToSuccess.vue";
import InquiryToFail from "@/views/pages/inquires/modals/InquiryToFail.vue";
import PerformanceViewModal from "@/views/pages/inquires/modals/PerformanceView.vue";
import moment from "moment";
import store from "@/state/store";
import axios from "axios";
import Swal from "sweetalert2";

export default {
  name: "List",
  components: {
    Table,
    ViewText,
    ViewComment,
    CreateInquiry,
    EditInquiry,
    DeleteInquiry,
    InquiryToSuccess,
    InquiryToFail,
    PerformanceViewModal
  },
  emits: ['updateStatistics'],
  data() {
    return {
      table: {
        name: 'Inquires List',
        url: '/inquiry/list/',
        headers: [
          {
            field: 'file',
            label: 'File',
            align: 'center',
            searchable: false
          },
          {
            field: 'sales_manager',
            label: 'Sales Manager',
            align: 'center',
            searchable: false
          },
          {
            field: 'text',
            label: 'Inquiry Message',
            maxWidth: '300px',
            align: 'center'
          },
          {
            field: 'client',
            label: 'Client',
            maxWidth: '400px',
            align: 'center'
          },
          {
            field: 'is_new_customer',
            label: 'New Lead',
            maxWidth: '400px',
            align: 'center',
            searchType: 'select',
            searchOptions: [
              {label: 'All', value: ''},
              {label: 'New Leads', value: 'true'},
              {label: 'Old Leads', value: 'false'},
            ]
          },
          {
            field: 'status',
            label: 'Status',
            align: 'center',
            searchType: 'select',
            searchOptions: [
              {label: 'All', value: ''},
              {label: 'Pending', value: 'pending'},
              {label: 'Quoted', value: 'quoted'},
              {label: 'Success', value: 'success'},
              {label: 'Fail', value: 'fail'},
            ]
          },
          {
            field: 'order',
            label: 'Order',
            align: 'center'
          },
          {
            field: 'description',
            label: 'Description',
            align: 'center',
            maxWidth: '400px',
          },
          {
            field: 'created',
            label: 'Created At',
            align: 'center',
            searchable: false
          },
          {
            field: 'actions',
            label: 'Actions',
            align: 'center',
          },
        ],
        searchable: true,
        selectable: true,
        indexed: true,
        enable_global_search: true
      },
      monthsWithIndex: [
        {index: 1, name: 'January'},
        {index: 2, name: 'February'},
        {index: 3, name: 'March'},
        {index: 4, name: 'April'},
        {index: 5, name: 'May'},
        {index: 6, name: 'June'},
        {index: 7, name: 'July'},
        {index: 8, name: 'August'},
        {index: 9, name: 'September'},
        {index: 10, name: 'October'},
        {index: 11, name: 'November'},
        {index: 12, name: 'December'}
      ],
      ORDER_CHILD_TYPE: {
        container_order: 'orders_container_detail',
        wagon_order: 'orders_wagon_detail',
        wagon_empty_order: 'orders_empty_wagon_detail'
      },
      managers_options: [],
      unknown_manager: {
        exists: false,
        is_hovered: false,
      },
      selected_year: new Date().getFullYear(),
      selected_month: new Date().getMonth() + 1,
    }
  },
  computed: {
    getUser() {
      return store.state.user
    },
    selected_month_name() {
      return new moment(`${this.selected_year}-${this.selected_month}-01`).format("MMMM");
    }
  },
  methods: {
    onTableLoad() {
      this.calculatePotentialKPI()
    },
    calculatePotentialKPI() {
      this.$refs.table.apiData = this.$refs.table.apiData.map(i => {
        let potential_response_time_kpi = {}
        let potential_completion_kpi = {}

        if (i.status === 'pending') {
          potential_response_time_kpi = this.timeLeftTillGivenDate(i.created, 'response_time')
        } else if (i.status === 'quoted') {
          potential_completion_kpi = this.timeLeftTillGivenDate(i.quoted_at, 'completion')
        }
        return {
          ...i,
          potential_response_time_kpi: potential_response_time_kpi,
          potential_completion_kpi: potential_completion_kpi,
        }
      })
    },
    async getAssignedManagers() {
      try {
        let response = await axios.get('/inquiry/assigned_managers/')
        this.managers_options = response.data.map(item => {
          return {
            value: item.sales_manager__id,
            label: item.sales_manager__username
          }
        })
        this.unknown_manager.exists = response.data.map(item => item.sales_manager__id).includes(null)
      } catch {
        this.managers_options = []
      }
    },
    async changeStatus(inquiry, new_status) {
      let Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
      });
      inquiry.is_saving_status = true;
      try {
        await axios.put(`/inquiry/update_with_notification/${inquiry.id}/`, {
          sender_id: inquiry.sender.id,
          text: inquiry.text,
          description: inquiry.description,
          status: new_status,
          sales_manager_id: inquiry.sales_manager ? inquiry.sales_manager.id : null,
          client: inquiry.client
        })
        inquiry.is_saving_status = false;
        inquiry.status = new_status;
        await this.onUpdate()
        await Toast.fire({
          icon: "success",
          title: "Inquiry status updated successfully",
        });
      } catch {
        inquiry.is_saving_status = false;
        await Toast.fire({
          icon: "error",
          title: "Error updating inquiry status",
        });
      }
    },
    async downloadFile(url_without_base) {
      let url = process.env.VUE_APP_ORDER_URL + url_without_base
      fetch(url)
          .then(resp => resp.blob())
          .then(blobobject => {
            const blob = window.URL.createObjectURL(blobobject);
            const anchor = document.createElement('a');
            anchor.style.display = 'none';
            anchor.href = blob;
            anchor.download = url.toString().split('/')[url.toString().split('/').length - 1];
            document.body.appendChild(anchor);
            anchor.click();
            window.URL.revokeObjectURL(blob);
          })
          .catch(() => alert('An error in downloading the file'));
    },
    async deleteFile(inquiry_id) {
      let Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
      });
      try {
        await axios.delete(`/inquiry/delete_file/${inquiry_id}/`)
        await this.$refs.table.getData()
        await Toast.fire({
          icon: "success",
          title: "File deleted successfully",
        });
      } catch {
        await Toast.fire({
          icon: "error",
          title: "Error deleting file",
        });
      }
    },
    uploadFile(event, inquiry_id) {
      let Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
      });
      try {
        let formData = new FormData();
        formData.append('file', event.target.files[0]);
        axios.post(`/inquiry/upload_file/${inquiry_id}/`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }).then(() => {
          Toast.fire({
            icon: "success",
            title: "File uploaded successfully",
          });
          this.$refs.table.getData()
        })
      } catch {
        Toast.fire({
          icon: "error",
          title: "Error uploading file",
        });
      }
    },
    deleteFileConfirmation(file, inquiry_id) {
      Swal.fire({
        position: "center",
        icon: "error",
        html: `
            <h3 class="mb-4">Are you sure that <br> you want to delete this file </h3>
            <span>${this.getFileName(file)}</span>
        `,
        showDenyButton: true,
        showConfirmButton: true,
        confirmButtonText: 'Yes, Delete It',
        denyButtonText: 'Cancel',
        cancelButtonColor: 'transparent',
        focusConfirm: false,
        inputLabel: `Please type YES to confirm`,
        input: 'text',
        inputPlaceholder: 'YES',
        inputValidator: (value) => {
          return new Promise((resolve) => {
            if (value === 'YES') {
              resolve(this.deleteFile(inquiry_id))
            } else {
              resolve('Confirmation did not match :)')
            }
          })
        }
      });
    },
    getFileName(file_path) {
      if (!file_path) return '';
      return file_path.toString().split('/')[file_path.toString().split('/').length - 1]
    },
    formatDateTime(date) {
      if (!date) return ''
      return moment(date).format('lll');
    },
    formatDateFromNow(date) {
      if (!date) return ''
      return moment(date, 'YYYY-MM-DDTHH:mm:ssZ').fromNow();
    },
    isWeekend() {
      const tomorrow = moment().add(1, 'days');
      const dayOfWeek = tomorrow.day();
      return dayOfWeek === 6 || dayOfWeek === 0;
    },

    msToHumanReadable(ms) {
      const seconds = Math.floor(ms / 1000);
      const minutes = Math.floor(seconds / 60);
      const hours = Math.floor(minutes / 60);

      const remainingMinutes = minutes % 60;
      const remainingSeconds = seconds % 60;

      let label = ""

      if (hours > 0) {
        label = label + `${hours} hours`
      }

      if (remainingMinutes > 0) {
        label = label + ` ${remainingMinutes} minutes`
      }

      if (hours === 0 && remainingMinutes) {
        label = label + ` ${remainingSeconds} seconds`
      }

      return label
    },

    timeLeftTillGivenDate(date, kpi_type) {
      let start = moment(date);
      let finishTime = start.clone();
      let addedHours = 0;
      let grade = 'A';

      let A_KPI_HOURS = kpi_type === 'response_time' ? 60 : 120
      let B_KPI_HOURS = kpi_type === 'response_time' ? 24 : 48

      while (addedHours < A_KPI_HOURS) {
        finishTime.add(1, 'hours');
        const dayOfWeek = finishTime.isoWeekday();
        if (dayOfWeek !== 6 && dayOfWeek !== 7) {
          addedHours++;
        }
      }

      let dateLabel = finishTime.format('YYYY-MM-DD HH:mm');

      let now = moment();
      let millisecondsLeft = Math.max(0, Math.ceil(finishTime.diff(now, 'milliseconds')));

      if (millisecondsLeft === 0) {
        grade = 'B'
        start = finishTime.clone();
        finishTime = start.clone();
        addedHours = 0;

        while (addedHours < B_KPI_HOURS) {
          finishTime.add(1, 'hours');
          const dayOfWeek = finishTime.isoWeekday();
          if (dayOfWeek !== 6 && dayOfWeek !== 7) { // Skip weekends
            addedHours++;
          }
        }

        millisecondsLeft = Math.max(0, Math.ceil(finishTime.diff(now, 'milliseconds')));

        if (millisecondsLeft === 0) {
          grade = 'C'
          millisecondsLeft = 0
        }
      }

      return {
        endDate: dateLabel,
        timeLeft: this.msToHumanReadable(millisecondsLeft),
        label: dateLabel,
        grade: grade,
      };
    },

    formatOrderPendingSince(date) {
      if (date) {
        const now = moment();
        const orderDate = moment(date, 'YYYY-MM-DDTHH:mm:ss.SSSSSSZ');
        const difference = now.diff(orderDate);

        const duration = moment.duration(difference);

        return `for ${duration.humanize()}`;
      }
      return 'Invalid date';
    },
    cleanText(html) {
      const tempElement = document.createElement('div');
      tempElement.innerHTML = html;
      return tempElement.textContent || tempElement.innerText;
    },
    openViewTextModal(data) {
      this.$refs.viewText.openModal(data);
    },
    openViewCommentModal(data) {
      this.$refs.viewComment.openModal(data);
    },
    async onUpdate() {
      await this.$refs.table.getData();
      this.$emit('updateStatistics')
    },
    filterByMonth(month) {
      const currentQuery = Object.assign({}, this.$route.query);
      if ('created' in currentQuery && parseInt(currentQuery.created || -1) === month) {
        delete currentQuery.created;
        this.$router.push({query: currentQuery});
      } else {
        this.$router.push({query: {...currentQuery, created: month}});
      }
    },
    filterByManager(manager_id) {
      const currentQuery = Object.assign({}, this.$route.query);
      if ('sales_manager' in currentQuery && parseInt(currentQuery.sales_manager || -1) === manager_id) {
        delete currentQuery.sales_manager;
        this.$router.push({query: currentQuery});
      } else {
        this.$router.push({query: {...currentQuery, sales_manager: manager_id}});
      }
    },
    async changeSelectedYear(year) {
      this.selected_year = year;
      await this.$router.push({
        query: {
          ...this.$route.query,
          created: new moment(`${this.selected_year}-${this.selected_month}-01`).format('YYYY-MM-DD')
        }
      });
    },
    async changeSelectedMonth(month_index) {
      this.selected_month = month_index;
      await this.$router.push({
        query: {
          ...this.$route.query,
          created: new moment(`${this.selected_year}-${this.selected_month}-01`).format('YYYY-MM-DD')
        }
      });
    },
    filterByUnknownManager() {
      const currentQuery = Object.assign({}, this.$route.query);
      if ('sales_manager' in currentQuery && currentQuery.sales_manager === "unknown") {
        delete currentQuery.sales_manager;
        this.$router.push({query: currentQuery});
      } else {
        this.$router.push({query: {...currentQuery, sales_manager: "unknown"}});
      }
    },
    closeAnyOPenDropdowns(id) {
      setTimeout(() => {
        const openDropdowns = document.querySelectorAll('.dropdown-menu.show');
        openDropdowns.forEach((dropdown) => {
          if ((dropdown.id && dropdown.id) === id) {
            return;
          }
          dropdown.classList.remove('show');
        });
      }, 3)
    },

    isAfterGivenDate(before, after) {
      const start = moment(before);
      const end = moment(after);

      return end.isAfter(start)
    }
  },
  async mounted() {

    if (this.$route.query.created) {
      try {
        const year = new moment(this.$route.query.created).year();
        const month = moment(this.$route.query.created).format('MM');

        this.selected_year = year
        this.selected_month = moment(this.$route.query.created).month() + 1

        await this.$router.push({
          query: {
            created: new moment(`${year}-${month}-01`).format('YYYY-MM-DD')
          }
        });
      } catch {
        console.log("error")
      }
    } else {
      try {
        const year = new moment(`${this.selected_year}-01-01`).year();
        const month = moment(`${this.selected_year}-${this.selected_month}-01`).format('MM');

        this.selected_year = year
        this.selected_month = moment(this.$route.query.created).month() + 1

        await this.$router.push({
          query: {
            created: new moment(`${year}-${month}-01`).format('YYYY-MM-DD')
          }
        });
      } catch {
        console.log("error")
      }
    }

    await this.getAssignedManagers()
    setInterval(() => {
      this.calculatePotentialKPI()
    }, 5000)
  },
}
</script>

<template>
  <ViewText ref="viewText"/>
  <ViewComment ref="viewComment" @onInquiryUpdated="onUpdate()"/>
  <CreateInquiry ref="createInquiry" @created="onUpdate()"/>
  <EditInquiry ref="editInquiry" @onInquiryUpdated="onUpdate()"/>
  <DeleteInquiry ref="deleteInquiry" @onInquiryDeleted="onUpdate()"/>
  <InquiryToSuccess ref="inquiryToSuccess" @inquiry-marked-as-success="onUpdate()"/>
  <InquiryToFail ref="inquiryToFail" @inquiry-marked-as-fail="onUpdate()"/>

  <PerformanceViewModal ref="performanceViewModal"/>

  <div>
    <Table @onLoaded="onTableLoad" v-bind="table" ref="table">

      <template v-slot:header_div>
        <div class="d-flex align-items-center flex-wrap mt-3 gap-3 pt-1" :class="{
          'justify-content-between': getUser.role.toLowerCase() === 'admin',
          'justify-content-end': getUser.role.toLowerCase() !== 'admin',
        }">
          <div class="d-flex flex-row gap-3 flex-wrap" v-if="getUser.role.toLowerCase() === 'admin'">
            <template v-for="(user, index) in managers_options" :key="`manager_filter_by_${user.value}`">
              <div v-if="user.value"
                   @mouseover="user.is_hovered = true"
                   @mouseleave="user.is_hovered = false"
                   @click="filterByManager(user.value)"
                   :class="{
                  'border-end pe-3' : index !== managers_options.length - 1,
                'border-primary': user.is_hovered || parseInt(this.$route.query.sales_manager || -1) === user.value,
                    }"
                   v-b-tooltip.hover :title="`Click to filter by ${user.label}`"
                   class="d-flex gap-2 align-items-center p-1 cursor-pointer border border-opacity-0 px-2 rounded-3"
              >
                <div class="avatar-xxs gap-3">
                  <div :class="{
                    'bg-primary text-white': parseInt(this.$route.query.sales_manager || -1) === user.value,
                    'bg-soft-primary text-primary': parseInt(this.$route.query.sales_manager || -1) !== user.value
                   }"
                       class="avatar-title text-uppercase rounded-circle flex-shrink-0 rounded cursor-pointer">
                    {{ getUser.id === user.value ? 'Me' : user.label[0] }}
                  </div>
                </div>
                <span>{{ getUser.id === user.value ? 'Me' : user.label }}</span>
              </div>
            </template>
            <div v-if="unknown_manager.exists"
                 @click="filterByUnknownManager()"
                 @mouseover="unknown_manager.is_hovered = true"
                 @mouseleave="unknown_manager.is_hovered = false"
                 :class="{
                'border border-danger rounded border-1': unknown_manager.is_hovered || this.$route.query.sales_manager === `unknown`,
                    }"
                 class="d-flex gap-2 align-items-center p-1 cursor-pointer">
              <div class="avatar-xxs gap-3">
                <div
                    :class="{
                  'bg-soft-danger text-danger': this.$route.query.sales_manager !== `unknown`,
                  'bg-danger text-white': this.$route.query.sales_manager === `unknown`
                    }"
                    class="bg-danger avatar-title text-uppercase rounded-circle flex-shrink-0 rounded cursor-pointer">
                  U
                </div>
              </div>
              <span class="text-danger">Unknown</span>
            </div>
          </div>
          <div>
            <BButtonGroup class="me-2">
              <BDropdown variant="light" :text="selected_year.toString()" auto-close="outside"
                         id="yearDropdown"
              >
                <BDropdownItem @click="changeSelectedYear(2025)" :active="selected_year === 2025">2025</BDropdownItem>
                <BDropdownItem @click="changeSelectedYear(2024)" :active="selected_year === 2024">2024</BDropdownItem>
              </BDropdown>
            </BButtonGroup>
            <BButtonGroup>
              <BDropdown variant="light" :text="selected_month_name.toString()" auto-close="outside"
                         id="monthDropdown"
              >
                <BDropdownItem
                    @click="changeSelectedMonth(month.index)"
                    v-for="month in monthsWithIndex" :key="`month_${month.index}`"
                    :active="selected_month === month.index"
                >
                  {{ month.name }}
                </BDropdownItem>
              </BDropdown>
            </BButtonGroup>
          </div>
        </div>
      </template>

      <template v-slot:top-right>
        <b-button @click="this.$refs.createInquiry.openModal()"
                  variant="success">
          <i class="ri-add-fill align-middle me-2"></i>
          Create Inquiry
        </b-button>
      </template>

      <template v-slot:file="{row:data}">
        <div v-if="data.file" class="dropdown">
          <button
              type="button"
              class="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle"
              data-bs-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
          >
            <i class="bx bx-file fs-4 align-middle"></i>
          </button>
          <div class="dropdown-menu dropdown-menu-end">
            <!-- item-->
            <a href="javascript:void(0);"
               class="dropdown-item py-2 d-flex align-items-center justify-content-between gap-4"
            >
              <span class="align-middle">{{ getFileName(data.file) }}</span>
              <div class="d-flex align-items-center justify-content-between gap-2">
                <i @click="downloadFile(data.file)" class="bx bx-download text-primary fs-4" v-b-tooltip.hover
                   title="Download"></i>
                <i @click="deleteFileConfirmation(data.file, data.id)" class="bx bx-trash-alt text-danger fs-4"
                   v-b-tooltip.hover
                   title="Delete"></i>
              </div>
            </a>
          </div>
        </div>
        <div v-else class="dropdown">
          <button
              type="button"
              class="btn btn-icon btn-topbar btn-ghost-success rounded-circle"
              data-bs-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
          >
            <i class="bx bx-plus-circle fs-4 align-middle"></i>
          </button>
          <div class="dropdown-menu dropdown-menu-end">
            <span class="px-3 pt-2 text-muted">
              Once you select a file, it will be uploaded immediately!
            </span>
            <!-- item-->
            <a href="javascript:void(0);"
               class="dropdown-item py-2"
            >
              <div>
                <input style="min-width: 200px" type="file" class="form-control" @change="uploadFile(
                    $event, data.id
                )"/>
              </div>
            </a>
          </div>
        </div>
      </template>

      <template v-slot:text="{row:data}">
        <div @click.stop="openViewTextModal(data)"
             class="text-truncate-two-lines text-start px-4 cursor-pointer" v-html="cleanText(data.text)"></div>
      </template>

      <template v-slot:client="{row:data}">
        <div class="text-truncate-two-lines text-start px-2" v-html="data.client"></div>
      </template>

      <template v-slot:sales_manager="{row: data}">
        <a class="fw-medium" :class="{
          'text-body': data.sales_manager,
          'text-danger': !data.sales_manager,
        }">{{ data.sales_manager ? data.sales_manager.username : "Unknown" }}</a>
      </template>

      <template v-slot:is_new_customer="{row: data}">
        <div class="d-flex align-items-center justify-content-center">
          <div class="form-check form-check-success">
            <input class="form-check-input" type="checkbox" disabled :checked="data.is_new_customer">
          </div>
        </div>
      </template>

      <template v-slot:order="{row: data}">
        <router-link v-if="data.platform === 'AG' && data.order" :to="{
          name: ORDER_CHILD_TYPE[data.order.child_type], params: {
            id: data.order.order_number
          }
        }" class="badge badge-soft-primary ">{{ data.order ? data.order.order_number : '' }}
        </router-link>
        <div v-else-if="data.platform === 'CA' && data.ca_order">
          CA: {{ data.ca_order }}
        </div>
        <span v-else>-</span>
      </template>
      <template v-slot:status="{row: data}">

        <div v-if="data.status === 'success'"
             class="text-start d-flex flex-column border p-1 rounded-3 px-2">
          <div class="mb-0">
            <span class="text-success fw-medium me-1">Success</span>
            <span v-if="isAfterGivenDate('2024-11-01', data.created)" v-b-tooltip.hover title="Completion KPI grade">
              - <span class="text-muted me-1">with</span>
              <span class="fw-medium" :class="{
                'text-success':  data.completion_grade === 'A',
                'text-warning':  data.completion_grade === 'B',
                'text-danger':  data.completion_grade === 'C',
              }">{{ data.completion_grade }}</span>
            </span>
          </div>
          <div>
            <span v-b-tooltip.hover :title="formatDateTime(data.success_at)">
              {{ formatDateFromNow(data.success_at) }}
            </span>
          </div>
        </div>

        <div v-else-if="data.status === 'fail'"
             class="text-start d-flex flex-column border p-1 rounded-3 px-2">
          <div class="mb-0">
            <span class="text-danger fw-medium me-1">Fail</span>
            <span v-if="isAfterGivenDate('2024-11-01', data.created)" v-b-tooltip.hover title="Completion KPI grade">
              - <span class="text-muted me-1">with</span>
              <span class="fw-medium" :class="{
                'text-success':  data.completion_grade === 'A',
                'text-warning':  data.completion_grade === 'B',
                'text-danger':  data.completion_grade === 'C',
              }">{{ data.completion_grade }}</span>
            </span>
          </div>
          <span v-b-tooltip.hover :title="formatDateTime(data.fail_at)">
            {{ formatDateFromNow(data.fail_at) }}
          </span>
        </div>

        <div v-else-if="data.status === 'pending'" class="dropdown">

          <div data-bs-toggle="dropdown">
            <div @click="closeAnyOPenDropdowns(`inquiry_status_${data.id}`)"
                 class="text-start d-flex flex-column border p-1 rounded-3 px-2 cursor-pointer">
              <div class="d-flex gap-2">
                <span class="text-warning fw-medium mb-0">
                  <i class="mdi mdi-pencil"></i> Pending
                </span>
                <template v-if="isAfterGivenDate('2024-11-01', data.created)">
                  <span class="text-muted">- {{ data.potential_response_time_kpi.grade === 'C' ? 'got' : 'get' }}</span>
                  <span class="fw-medium" :class="{
                  'text-success': data.potential_response_time_kpi.grade === 'A',
                  'text-warning': data.potential_response_time_kpi.grade === 'B',
                  'text-danger': data.potential_response_time_kpi.grade === 'C',
                }">{{ data.potential_response_time_kpi.grade }}</span>
                </template>
              </div>

              <!--              <template v-if="isAfterGivenDate('2024-11-01', data.created)">-->
              <!--                <div class="text-muted" v-if="data.potential_completion_kpi.grade === 'C'">-->
              <!--                  for completion KPI-->
              <!--                </div>-->
              <!--                <div v-b-tooltip.hover-->
              <!--                     :title="'Complete until ' + data.potential_completion_kpi.label + ' to get ' + data.potential_completion_kpi.grade"-->
              <!--                     v-else class="text-muted">-->
              <!--                  within {{ data.potential_completion_kpi.timeLeft }}-->
              <!--                </div>-->
              <!--              </template>-->
              <!--              <div v-else v-b-tooltip.hover :title="`Quoted - ${formatDateTime(data.quoted_at)}`">-->
              <!--                {{ formatDateFromNow(data.quoted_at) }}-->
              <!--              </div>-->
              <!--              -->

              <template v-if="isAfterGivenDate('2024-11-01', data.created)">
                <div class="text-muted" v-if="data.potential_response_time_kpi.grade === 'C'">
                  for response-time KPI
                </div>
                <div v-b-tooltip.hover
                     :title="'Quote until ' + data.potential_response_time_kpi.label + ' to get ' + data.potential_response_time_kpi.grade"
                     v-else class="text-muted">
                  within {{ data.potential_response_time_kpi.timeLeft }}
                </div>
              </template>

              <div v-else v-b-tooltip.hover :title="`Created - ${formatDateTime(data.created)}`">
                {{ formatOrderPendingSince(data.created) }}
              </div>

            </div>
          </div>

          <div class="dropdown-menu dropdown-menu-end" :id="`inquiry_status_${data.id}`">

            <div class="px-3 py-0 mb-2">
              <small v-if="data.is_saving_status !== true" class="text-muted">Mark as</small>
              <small class="text-muted" v-else>
                <i class="mdi mdi-loading mdi-spin me-2"></i>
                Saving...
              </small>
            </div>

            <div class="dropdown-item notify-item cursor-pointer"
                 :class="{'active': data.status === 'quoted'}"
                 @click="changeStatus(data, 'quoted')"
            >
              <i class="mdi mdi-check text-secondary me-2 my-0"></i>
              <h6 class="my-0 py-0 d-inline">Quoted</h6>
            </div>

          </div>
        </div>

        <div v-else-if="data.status === 'quoted'" class="dropdown">

          <div data-bs-toggle="dropdown">
            <div @click="closeAnyOPenDropdowns(`inquiry_status_${data.id}`)"
                 class="text-start d-flex flex-column border p-1 rounded-3 px-2 cursor-pointer">
              <div class="mb-0">
                <span class="text-secondary fw-medium me-1">Quoted</span>
                <span v-if="isAfterGivenDate('2024-11-01', data.created)" v-b-tooltip.hover
                      title="Completion KPI grade">
                  - <span
                    class="text-muted me-1">{{ data.potential_completion_kpi.grade === 'C' ? 'got' : 'get' }}</span>
                    <span class="fw-medium" :class="{
                      'text-success':  data.potential_completion_kpi.grade === 'A',
                      'text-warning':  data.potential_completion_kpi.grade === 'B',
                      'text-danger':  data.potential_completion_kpi.grade === 'C',
                    }">{{ data.potential_completion_kpi.grade }}</span>
                    </span>
              </div>


              <template v-if="isAfterGivenDate('2024-11-01', data.created)">
                <div class="text-muted" v-if="data.potential_completion_kpi.grade === 'C'">
                  for completion KPI
                </div>
                <div v-b-tooltip.hover
                     :title="'Complete until ' + data.potential_completion_kpi.label + ' to get ' + data.potential_completion_kpi.grade"
                     v-else class="text-muted">
                  within {{ data.potential_completion_kpi.timeLeft }}
                </div>
              </template>
              <div v-else v-b-tooltip.hover :title="`Quoted - ${formatDateTime(data.quoted_at)}`">
                {{ formatDateFromNow(data.quoted_at) }}
              </div>
            </div>
          </div>

          <div class="dropdown-menu dropdown-menu-end" :id="`inquiry_status_${data.id}`">

            <div class="px-3 py-0 mb-2">
              <small v-if="data.is_saving_status !== true" class="text-muted">Mark as</small>
              <small class="text-muted" v-else>
                <i class="mdi mdi-loading mdi-spin me-2"></i>
                Saving...
              </small>
            </div>

            <div class="dropdown-item notify-item cursor-pointer"
                 @click="this.$refs.inquiryToSuccess.openModal(data)"
            >
              <i class="mdi mdi-check text-success me-2 my-0"></i>
              <h6 class="my-0 py-0 d-inline">Success</h6>
            </div>

            <div class="dropdown-item notify-item cursor-pointer"
                 @click="this.$refs.inquiryToFail.openModal(data)"
            >
              <i class="bx bx-x text-danger me-2 fs-5 align-middle my-0"></i>
              <h6 class="my-0 py-0 d-inline">Fail</h6>
            </div>

          </div>
        </div>

      </template>
      <template v-slot:description="{row:data}">
        <div class="text-truncate" v-if="data.description.trim().length"
             @click.stop="openViewCommentModal(data)"
             v-html="cleanText(data.description)"></div>
        <span @click.stop="openViewCommentModal(data)" v-else>-</span>
      </template>
      <template v-slot:created="{row: data}">
        <div class="d-inline-block w-100">
          <div class="d-flex flex-column justify-content-start align-items-start gap-1">
          <span v-b-tooltip.hover title="Date created">
            <i class="mdi mdi-clock text-muted"></i>
            {{ formatDateTime(data.created) }}</span>
            <span v-b-tooltip.hover title="Date modified">
            <i class="mdi mdi-pencil text-muted"></i>
            {{ formatDateTime(data.modified) }}</span>
          </div>
        </div>
      </template>
      <template v-slot:actions="{row: data}">
        <b-button-group class="border rounded-3" role="group" size="md" aria-label="Basic example">
          <b-button
              @click="this.$refs.editInquiry.openModal(data);"
              variant="light" class="pe-2">
            <i class="ri-pencil-fill align-bottom"></i>
          </b-button>
          <b-button
              @click="this.$refs.deleteInquiry.openModal(data);"
              variant="light">
            <i class="ri-delete-bin-7-fill align-bottom text-danger"></i>
          </b-button>
        </b-button-group>
      </template>
    </Table>
  </div>
</template>

<style scoped>
.text-truncate-two-lines {
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  white-space: normal;
}
</style>